import React from 'react';
import { graphql, Link } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import SEO from '../components/seo';
import COLORS from '../constants/colors';
import Header from '../components/Header';
import PageContainer from '../components/Containers/PageContainer';
import Layout from '../components/layout';
import { FormattedMessage } from 'gatsby-plugin-intl';
import SIZES from '../constants/sizes';
import FONTS from '../constants/fonts';
import { shape } from 'prop-types';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
      marginBottom: 0,
    },
  },
  textGrid: {
    padding: '0px 0px 40px 160px',
    [theme.breakpoints.down(1024)]: {
      padding: '0px 0px 40px 100px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 40px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  heroImage: {
    height: 400,
    [theme.breakpoints.down('xs')]: {
      marginLeft: -20,
      marginRight: -20,
      height: 'auto',
    },
  },
  subTitle: {
    color: COLORS.DARK_BLUE,
    paddingTop: 40,
    paddingBottom: 40,
    maxWidth: 750,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      width: '100%',
      marginBottom: 26,
    },
  },
  body: {
    width: '60%',
    paddingTop: 60,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  backLink: {
    fontSize: '16px',
    color: COLORS.BLUE,
    margin: '24px 0',
    fontWeight: FONTS.WEIGHT.BOLD,
    textDecoration: 'none',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
      textDecorationColor: COLORS.BLUE,
    },
  },
  newsPost: {
    whiteSpace: 'pre-line',
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
    lineHeight: 1.4,
    maxWidth: 750,
    '& a': {
      color: COLORS.BLUE,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: COLORS.BLUE,
      },
      '&:visited': {
        color: COLORS.DARK_BLUE,
      },
    },
  },
  title: {
    paddingBottom: 16,
    color: COLORS.DARK_BLUE,
    marginLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    [theme.breakpoints.down('md')]: {
      marginLeft: SIZES.PADDING.LEFT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
}));

const BlogPostTemplate = ({ data }) => {
  const classes = useStyles();
  const post = data.contentfulScanshipNewsPost;

  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.metaDescription}
        image={post.image.fluid.src}
      />
      <PageContainer>
        <Header
          sub
          textTransform="lowerCase"
          className={classes.title}
        >
          <FormattedMessage id="News.title" />
        </Header>
        <Container maxWidth="xl"></Container>
        <Container className={classes.textGrid}>
          <Link to="/news" className={classes.backLink}>
            Back to news
          </Link>
          <Img
            className={classes.heroImage}
            alt={post.title}
            fluid={post.image.fluid}
          />
          <h2 className={classes.subTitle}>{post.title}</h2>
          <p
            style={{
              display: 'block',
              fontWeight: 'bold',
              marginBottom: 16,
              color: COLORS.DARK_BLUE,
            }}
          >
            {post.publishDate}
          </p>
          <span className={classes.newsPostObj}>
            <div
              className={classes.newsPost}
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </span>
          <Link to="/news" className={classes.backLink}>
            Back to news
          </Link>
        </Container>
      </PageContainer>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: shape(),
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulScanshipNewsPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      image {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
