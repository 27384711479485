import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  string,
  element,
  arrayOf,
  oneOfType,
} from 'prop-types';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/sizes';

const useStyle = makeStyles(theme => ({
  PageContainer: {
    display: 'flex',
    flexFlow: 'column',
    paddingTop: SIZES.PADDING.TOP_PADDING.DEFAULT,
    paddingBottom: SIZES.PADDING.BOTTOM_PADDING.DEFAULT,
    backgroundColor: COLORS.WHITE,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      paddingTop: SIZES.PADDING.TOP_PADDING.MD,
      paddingBottom: SIZES.PADDING.BOTTOM_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: SIZES.PADDING.TOP_PADDING.XS,
      paddingBottom: SIZES.PADDING.BOTTOM_PADDING.XS,
    },
  },
}));

const PageContainer = ({ children, className }) => {
  const classes = useStyle();

  return (
    <div
      className={`${classes.PageContainer} ${className}`}
    >
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  className: string,
  children: oneOfType([element, arrayOf(element)])
    .isRequired,
};

PageContainer.defaultProps = {
  className: '',
};

export default PageContainer;
